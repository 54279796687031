import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: './pages/home/home.module#HomeModule',
    pathMatch: 'full'
  },
  {
    path: 'vinos',
    loadChildren: './pages/vinos/vinos.module#VinosModule',
  },
  {
    path: ':menu',
    loadChildren: './pages/menu/menu.module#MenuModule',
    data: {
      menu: 'Comida'
    }
  },
  {
    path: '**', // Cualquier ruta (default) redirecciona a...
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
     useHash: false,
     scrollOffset: [0, 80]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
